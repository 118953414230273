
@import './variables.scss';

.mt-0 {
  margin-top: 0em !important;
}

.mt-05 {
  margin-top: 0.25em !important;
}

.mt-1 {
  margin-top: 0.5em !important;
}

.mt-2 {
  margin-top: 1em !important;
}

.mt-3 {
  margin-top: 1.5em !important;
}

.mt-4 {
  margin-top: 2em !important;
}

.mt-5 {
  margin-top: 2.5em !important;
}

.ml-0 {
  margin-left: 0em !important;
}

.ml-05 {
  margin-top: 0.25em !important;
}

.ml-1 {
  margin-left: 0.5em !important;
}

.ml-2 {
  margin-left: 1em !important;
}

.ml-3 {
  margin-left: 1.5em !important;
}

.ml-4 {
  margin-left: 2em !important;
}

.ml-5 {
  margin-left: 2.5em !important;
}

.mr-0 {
  margin-right: 0em !important;
}

.mr-05 {
  margin-top: 0.25em !important;
}

.mr-1 {
  margin-right: 0.5em !important;
}

.mr-2 {
  margin-right: 1em !important;
}

.mr-3 {
  margin-right: 1.5em !important;
}

.mr-4 {
  margin-right: 2em !important;
}

.mr-5 {
  margin-right: 2.5em !important;
}

.mb-0 {
  margin-bottom: 0em !important;
}

.mb-05 {
  margin-top: 0.25em !important;
}

.mb-1 {
  margin-bottom: 0.5em !important;
}

.mb-2 {
  margin-bottom: 1em !important;
}

.mb-3 {
  margin-bottom: 1.5em !important;
}

.mb-4 {
  margin-bottom: 2em !important;
}

.mb-5 {
  margin-bottom: 2.5em !important;
}

.m-0 {
  margin: 0em !important;
}

.m-05 {
  margin: 0.25em !important;
}

.m-1 {
  margin: 0.5em !important;
}

.m-2 {
  margin: 1em !important;
}

.m-3 {
  margin: 1.5em !important;
}

.m-4 {
  margin: 2em !important;
}

.m-5 {
  margin: 2.5em !important;
}

.pt-0 {
  padding-top: 0em !important;
}

.pt-1 {
  padding-top: 0.5em !important;
}

.pt-2 {
  padding-top: 1em !important;
}

.pt-3 {
  padding-top: 1.5em !important;
}

.pt-4 {
  padding-top: 2em !important;
}

.pt-5 {
  padding-top: 2.5em !important;
}

.pl-0 {
  padding-left: 0em !important;
}

.pl-1 {
  padding-left: 0.5em !important;
}

.pl-2 {
  padding-left: 1em !important;
}

.pl-3 {
  padding-left: 1.5em !important;
}

.pl-4 {
  padding-left: 2em !important;
}

.pl-5 {
  padding-left: 2.5em !important;
}

.pr-0 {
  padding-right: 0em !important;
}

.pr-1 {
  padding-right: 0.5em !important;
}

.pr-2 {
  padding-right: 1em !important;
}

.pr-3 {
  padding-right: 1.5em !important;
}

.pr-4 {
  padding-right: 2em !important;
}

.pr-5 {
  padding-right: 2.5em !important;
}

.pb-0 {
  padding-bottom: 0em !important;
}

.pb-1 {
  padding-bottom: 0.5em !important;
}

.pb-2 {
  padding-bottom: 1em !important;
}

.pb-3 {
  padding-bottom: 1.5em !important;
}

.pb-4 {
  padding-bottom: 2em !important;
}

.pb-5 {
  padding-bottom: 2.5em !important;
}

.p-0 {
  padding: 0em !important;
}

.p-1 {
  padding: 0.5em !important;
}

.p-2 {
  padding: 1em !important;
}

.p-3 {
  padding: 1.5em !important;
}

.p-4 {
  padding: 2em !important;
}

.p-5 {
  padding: 2.5em !important;
}

.height-30px{
  height: 30px;
}

.w-100 {
  width: 100%;
}

.max-w-100vw{
  max-width: 100vw;
}

.h-100 {
  height: 100%;
}

.max-h-100vh{
  max-height: 100vh;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-white {
  color: $white !important;
}

.text-overflow-ellipsis{
  text-overflow: ellipsis;
}

.overflow-hidden{
  overflow: hidden;
}

.text-nowrap{
  white-space: nowrap;
}

.p-relative {
  position: relative !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.select-none{
  user-select: none !important;
}

.flex-columns{
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.responsive-rows{
  flex-wrap: wrap;
  gap: 1em;

  @media (max-width: 600px) {
    .flex-1:nth-child(2n){
      flex-basis: 100%;
    }
  }
}

.flex-1{
  flex: 1;
}

.min-width-100{
  min-width: 100px;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around
}

.justify-content-start {
  justify-content: flex-start
}

.justify-content-end {
  justify-content: flex-end
}

.align-items-end{
  align-items: flex-end;
}

.align-items-start{
  align-items: flex-start;
}

.align-items-center{
  align-items: center;
}

h1 {
  font-size: 1.6em;
  line-height: 38px;
}

.cursor-pointer{
  cursor: pointer;
}

.pointer-events-none{
  pointer-events: none;
}

.rotate-90{
  transform: rotate(90deg);
}

.no-decoration{
  text-decoration: none;
}